<template>
  <div class="log">
    <div class="mb-10 font-semibold">Google近一小时订单通知：</div>
    <ad-table-page
      :showForm="false"
      v-model="formData"
      :tableConfig="tableConfig"
      class="h-full"
    >
    </ad-table-page>
  </div>
</template>
<script>
import pageTable from '@/components/common/PageTable';
import { adTablePage } from '@adCommonComponent';
import { recentHourOrder } from '@/api/multiPlatform.js';
export default {
  components: { adTablePage },
  data() {
    return {
      formData: {},
      params: {},
      tableConfig: {
        api: recentHourOrder,
        formData: {},
        columns: [
          {
            label: '序号',
            type: 'index',
            width: 80,
          },

          {
            label: '系列名称',
            prop: 'campaignName',
          },
          {
            label: '系列id',
            prop: 'campaignId',
          },
          {
            label: '产品名称',
            prop: 'productName',
          },
          {
            label: '近1小时订单',
            prop: 'orderCount',
          },
          {
            label: '近1小时花费',
            prop: 'spend',
          },
          {
            label: '近1小时订单成本',
            prop: 'avgOrderAmount',
          },
        ],
        showPage: false,
        refresh: true,
        formatterParams(params) {
          console.log(params);
          let { order, pageNum, pageSize, prop, ...data } = params;
          //   data.dateHour = encodeURIComponent(data.dateHour);
          return data;
        },
        formatterTableData(data) {
          console.log(data);
          return {
            list: data.data,
          };
        },
      },
    };
  },
  methods: {
    formatterParams(params) {},
    formatterTableData(data) {
      return { content: data };
    },
  },
  async created() {
    await this.$store.dispatch('app/closeSideBar');
  },
  mounted() {
    this.formData = {
      ...this.$route.query,
      //   dateHour: decodeURIComponent(this.$route.query.dateHour),
    };
    this.tableConfig.refresh = !this.tableConfig.refresh;
  },
};
</script>
<style lang="scss" scope>
.log {
  .el-table__body-wrapper {
    height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
